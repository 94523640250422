<template>
  <form
    action="/search"
    method="GET"
    @submit.prevent="handleSearch(modelValue)"
  >
    <Combobox v-model="selected">
      <div class="relative rounded-full shadow-sm mx-auto max-w-md">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
        </div>
        <ComboboxInput
          id="searchString"
          ref="searchFieldRef"
          type="search"
          name="searchString"
          class="block w-full rounded-full border-0 px-10 text-gray-900 bg-white ring-1 ring-inset ring-gray-300 appearance-none placeholder:text-gray-400 focus:ring-2 focus:ring-gray-200 focus:outline-0 sm:text-sm sm:leading-6 input"
          :class="{ 'py-3': !mini, 'py-2.5': mini }"
          placeholder="Name, zipcode or specialty"
          autocomplete="off"
          autocapitalize="off"
          autocorrect="off"
          spellcheck="false"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          @change="fetchTypeahead($event.target.value)"
        />
        <div class="absolute inset-y-0 right-0 flex items-center pr-3">
          <button
            v-if="modelValue"
            type="button"
            class="flex justify-center flex-col items-center text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 w-8 h-8"
            @click="
              $emit('update:modelValue', '');
              hits = [];
              $refs.searchFieldRef.el.focus();
            "
          >
            <XMarkIcon class="h-4 w-4" />
          </button>
        </div>

        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            v-if="modelValue"
            class="absolute mt-1 sm:max-h-60 h-screen w-full overflow-auto rounded-md bg-white py-1 text-base sm:shadow-lg sm:ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ComboboxOption
              v-if="modelValue"
              v-slot="{ selected, active }"
              :value="modelValue"
            >
              <li
                class="relative cursor-default select-none py-2 sm:pl-5 pl-3 pr-4 flex items-center"
                :class="{
                  'sm:bg-gray-200 text-gray-900': active,
                  'text-gray-900': !active,
                }"
              >
                <MagnifyingGlassIcon class="h-4 w-4 ml-2" />
                <span class="block truncate ml-4">
                  {{ modelValue }}
                </span>
              </li>
            </ComboboxOption>
            <ComboboxOption
              v-for="person in hits"
              :key="person.id"
              v-slot="{ selected, active }"
              as="template"
              :value="person"
            >
              <li
                class="relative cursor-default select-none py-2 sm:pl-5 pl-3 pr-4"
                :class="{
                  'sm:bg-gray-200 text-gray-900': active,
                  'text-gray-900': !active,
                }"
              >
                <div v-if="person.slug" class="flex items-center gap-x-2">
                  <ProfileImage
                    :provider="person"
                    class="h-8 w-8"
                    size="small"
                  />

                  <div class="w-72">
                    <div class="truncate">{{ person.name }}</div>
                    <div
                      v-if="!person.isCompany"
                      class="truncate text-xs text-gray-500"
                    >
                      {{ person.companyName }}
                    </div>
                  </div>
                </div>
                <div v-else class="flex items-center">
                  <MagnifyingGlassIcon class="h-4 w-4 ml-2" />
                  <span class="block truncate ml-4">
                    {{ person.name }}
                  </span>
                </div>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </form>
</template>
<script setup>
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  BuildingOffice2Icon,
} from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import { getAnalytics, logEvent } from 'firebase/analytics';

const props = defineProps([
  'modelValue',
  'mini',
  'autofocus',
  'searchFieldRef',
  'autofocus',
]);
const emit = defineEmits(['update:modelValue', 'update:selected']);
const hits = ref([]);
const searchFieldRef = ref(null);

const selected = ref(null);
const queryString = ref('');
const route = useRoute();

const fetchTypeahead = useDebounceFn(async (query) => {
  const userLocation = useUserLocation();
  const { data, pending } = await useLegacyApiFetch('/api/typeahead', {
    query: {
      query: query ?? '',
      aroundLatLng: `${userLocation.value.latitude}, ${userLocation.value.longitude}`,
    },
  });
  hits.value = data.value.filter((hit, index) => index < 10);
  queryString.value = query;
}, 80);

const handleSearch = async (searchQuery) => {
  let newValue = searchQuery ?? selected.value;
  if (!newValue) newValue = queryString.value;

  const analytics = getAnalytics();
  logEvent(analytics, 'search', {
    query: props.modelValue,
    slug: newValue?.slug,
    docType: newValue?.docType,
    name: newValue?.name,
    from: 'banner',
  });

  if (newValue.slug) {
    await navigateTo(getProviderLink(newValue));
  } else if (newValue.docType === 'specialty') {
    await navigateTo(`/dmi`);
  } else if (newValue.toLowerCase() === 'dmi') {
    await navigateTo('/dmi');
  } else {
    const params = {};

    if (route.query.filter) {
      params.filter = route.query.filter;
    }
    params.q = newValue;

    const paramString = new URLSearchParams(params).toString();
    await navigateTo(`/search?${paramString}`);
  }
};

watch(
  () => selected.value,
  (newValue) => {
    handleSearch();
  },
);
</script>
<style>
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
