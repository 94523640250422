<template>
  <button
    class="relative rounded-full shadow-sm mx-auto w-full block sm:hidden"
    type="button"
    @click.prevent="handleClick"
  >
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
    >
      <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
    </div>
    <input
      id="q"
      type="search"
      name="q"
      class="pointer-events-none block w-full appearance-none rounded-full border-0 px-10 text-gray-900 bg-white ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
      :class="{ 'py-3': !mini, 'py-2.5': mini }"
      placeholder="Name, zipcode or specialty"
      :value="modelValue"
      readonly
    />
  </button>
  <ClientOnly>
    <Dialog :open="isSearchViewOpen" @close="router.back(-1)">
      <DialogPanel
        class="fixed top-0 pt-2 z-50 h-screen w-screen bg-white overflow-auto"
      >
        <div class="mx-5 mt-2">
          <div class="flex ml-1">
            <div
              class="btn btn-circle btn-ghost h-8 w-8 min-h-0"
              @click="$router.go(-1)"
            >
              <ArrowLeftIcon class="w-5 h-5 text-gray-500" />
            </div>
          </div>
          <form action="/search" method="GET">
            <SearchBar v-model="query" class="w-full mt-2" autofocus="1" />
          </form>
        </div>
      </DialogPanel>
    </Dialog>
  </ClientOnly>
</template>
<script setup>
import { MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { Dialog, DialogPanel } from '@headlessui/vue';
defineProps(['modelValue', 'mini']);
defineEmits(['update:modelValue']);

const isSearchViewOpen = ref(false);

const handleClick = () => {
  isSearchViewOpen.value = true;
  navigateTo(route.fullPath.split('#')[0] + '#m');
};

const route = useRoute();
const query = ref(route.query.q ?? '');
const router = useRouter();
watch(
  () => route.hash,
  (hash) => {
    isSearchViewOpen.value = hash === '#m';
  },
);
</script>
